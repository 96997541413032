<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder"><vb-hidden-kit-antd /></div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHiddenKitAntd from '@/@vb/widgets/Hidden/KitAntd'

export default {
  name: 'VbAntDesign',
  components: {
    VbHiddenKitAntd,
  },
}
</script>
